/* eslint-disable jsx-a11y/label-has-associated-control */
import {
  Box,
  Button,
  Chip,
  Stack,
  Paper,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useState, useEffect } from 'react';
import CommentDialog from 'src/components/comment-dialog';
import Scrollbar from 'src/components/scrollbar';
import { useTable } from 'src/components/table';
import CommercialInvoiceDialog from './commercialInvoiceDialog';
// import { AdminPaymentDisapprove, AdminPaymentApprove } from 'src/services/accounting';
import { calculateClientUnitPrice } from 'src/utils/quoteHelperFunc';
import { useParams } from 'src/routes/hooks';
import { useSnackbar } from 'src/components/snackbar';
import { North, South } from '@mui/icons-material';
import { LineItemMoveDownApi, LineItemMoveUpApi, updateWarrantyApi } from 'src/services/common';
import CommentsModal from 'src/components/comments/index';
import CountComment from 'src/components/comments/CountComment';
import { primaryPresets } from 'src/theme/options/presets';
import { formatCurrencyWithSymbol } from 'src/utils/currencyFormat';
function createData(
  _id,
  slNo,
  desc,
  pn,
  unitPrice,
  totalPrice,
  qty,
  make,
  AdminPaymentApproval,
  warrantyPeriod,
  index,
  RecentlyEditedBy
) {
  return {
    _id,
    slNo,
    desc,
    pn,
    unitPrice,
    totalPrice,
    qty,
    make,
    AdminPaymentApproval,
    warrantyPeriod,
    index,
    RecentlyEditedBy
  };
}

// function createDataFIx(
//   contractAmount,
//   advancePaymentReceived,
//   balancePayment,
//   totalAmountPaid,
//   comment,
//   commercialInvoice,
//   binderFileDeliveryProof,
//   commercialInvoiceBinderFile,
//   commercialInvoiceBankingTaxing,
//   approvalAdmin,
//   ledger
// ) {
//   return {
//     contractAmount,
//     advancePaymentReceived,
//     balancePayment,
//     totalAmountPaid,
//     comment,
//     commercialInvoice,
//     binderFileDeliveryProof,
//     commercialInvoiceBinderFile,
//     commercialInvoiceBankingTaxing,
//     approvalAdmin,
//     ledger,
//   };
// }

export default function AccClientLevel1Table({ response, setRefresh, refresh, setLoading }) {
  const table = useTable({ defaultRowsPerPage: 10000 });
  const [showDialog, setShowDialog] = useState(false);
  // const [warrantyPeriod, setWarrantyPeriod] = useState('');
  // const [warrantyStartDate, setWarrantyStartDate] = useState('');
  const [showInvoiceDialog, setShowInvoiceDialog] = useState(false);
  const [rowData, setRowData] = useState(null);
  
  const { enqueueSnackbar } = useSnackbar();
  // const router = useRouter();
  const { id } = useParams();

  const [isCommentOpen, setIsCommentOpen] = useState(false);
  const [commentId, setCommentId] = useState(null);

  const handleComments = (row) => {
    setCommentId(row?._id);
    setIsCommentOpen(true);
  };

  useEffect(() => {
    if (response?.line_items?.length) {
      const rowArr = response?.line_items?.map((list, ind) => {
        return createData(
          list?._id,
          list?.serialNumber,
          list?.description ? list?.description : '--',
          list?.PN ? list?.PN : '--',
          formatCurrencyWithSymbol(calculateClientUnitPrice(list, response), response?.currency?.abbreviation),
          formatCurrencyWithSymbol(
            calculateClientUnitPrice(list, response) * list?.quantity,
            response?.currency?.abbreviation
          ),
          list?.quantity ? list?.quantity : '--',
          list?.make ? list?.make : '--',
          response?.InitiatePaymentApproval,
          list.warranty,
          ind,
          list?.RecentlyEditedBy ? list?.RecentlyEditedBy :[]
        );
      });

      setRowData(rowArr);
    }
  }, [response]);

  const COLUMNS = [
    {
      label: 'Action',
      id: 'action',
      width: 250,
      render: (value, row, length) => (
        <Box mr={2}>
          <Stack>
            {row?.index === 0 ? (
              ''
            ) : (
              <Button onClick={() => MoveUp(id, row?._id)}>
                <North />
                <Typography variant="caption">MOVE UP</Typography>{' '}
              </Button>
            )}
            {row?.index + 1 === length ? null : (
              <Button onClick={() => MoveDown(id, row?._id)}>
                <South />
                <Typography variant="caption">MOVE DOWN</Typography>{' '}
              </Button>
            )}
            {length === 1 ? <Typography variant="caption">No Action</Typography> : null}
          </Stack>
        </Box>
      ),
    },
    {
      id: 'slNo',
      label: 'SNO.',
      minWidth: 50,
      align:"center",
    },
    {
      id: 'desc',
      label: 'Description',
      align:"center",
      render: (value, row) => <Box sx={{width:"100%", display:'flex', justifyContent:'center'}}><Typography style={{ wordBreak: 'break-word',width: 300 , pl:3}}>{value}</Typography></Box>,
    },
    {
      id: 'pn',
      label: 'P/N',
      minWidth: 200,
      align:"center",
    },
    {
      id: 'unitPrice',
      label: '(Client) Unit Price',
      minWidth: 120,
      align:"center",
    },
    {
      id: 'totalPrice',
      label: '(Client) Total Price',
      minWidth: 130,
      align:"center",
    },
    {
      id: 'qty',
      label: 'QUANTITY',
      minWidth: 100,
      align:"center",
    },
    {
      id: 'make',
      label: 'Make',
      minWidth: 200,
      align:"center",
      render: (value, row) => <Typography style={{ wordBreak: 'break-word' }}>{value}</Typography>,
    },
    {
      id: 'comment',
      label: 'Comments',
      minWidth: 170,
      align:"center",
      render: (value, row) => (
        <Box
          onClick={() => {
            setShowDialog(true);
            handleComments(row);
          }}
          display="flex"
          flexDirection="column"
        >
          <Box sx={{ ml: 2.5 }}>
            {/* <Iconify width={20} icon="ic:outline-chat" /> */}
            <CountComment projectId={row?._id} isCommentOpen={isCommentOpen} />
          </Box>
        </Box>
      ),
    },
    {
      id: 'AdminPaymentApproval',
      label: 'Approval of Admin',
      minWidth: 100,
      align:"center",
      render: (value, row) => (
        <>
        <Chip
          style={{ textTransform: 'uppercase' }}
          color={value ? 'success' : 'warning'}
          label={value ? 'Approved' : 'Pending'}
        />
        {console.log(value, "ashuashau")}
        </>
      ),
    },
    {
      id: 'warrantyPeriod',
      label: 'Warranty Period',
      minWidth: 260,
      align:"center",
      render: (value, row) => (
        <Box>
          <TextField
            fullWidth
            label="Enter Warranty Period"
            defaultValue={value}
            disabled={true}
            onChange={(e) => onChangeWarrantyPeriod(e.target.value, row?._id)}
          />
        </Box>
      ),
    },
    {
      id: 'user',
      label: 'USER',
      minWidth: 150,
      render: (value, row) => (
        <Box>
          <Typography>
            Edited By:{' '}
            {row?.RecentlyEditedBy?.slice(-1)[0] ? row?.RecentlyEditedBy.slice(-1)[0] : '--'}
          </Typography>
        </Box>
      ),
    },
  ];

  const MoveDown = (id, lineItem_id) => {
    setLoading(true);
    LineItemMoveDownApi(id, lineItem_id)
      .then(() => {
        setRefresh(refresh + 1);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        enqueueSnackbar('Error! Please try again');
      });
  };
  const MoveUp = (id, lineItem_id) => {
    setLoading(true);
    LineItemMoveUpApi(id, lineItem_id)
      .then(() => {
        setRefresh(refresh + 1);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        enqueueSnackbar('Error! Please try again');
      });
  };

  // const HandleAdminApproval = (data) => {
  //   if (data?.isApproved) {
  //     AdminPaymentDisapprove(data?._id);
  //   } else {
  //     AdminPaymentApprove(data?._id);
  //   }
  // };

  // const onChangeWarrantyStartDate = (e) => {};



  const onChangeWarrantyPeriod = (val, item_id) => {
    updateWarrantyApi(id, item_id, val);
  };

  return (
    <Box>
      <CommentsModal
        isCommentOpen={isCommentOpen}
        setIsCommentOpen={setIsCommentOpen}
        id={commentId}
      />
      <Paper elevation={1} sx={{p:2}}>
        <TableContainer sx={{ overflow: 'unset' }}>
          <Scrollbar sx={{ maxHeight: '80vh' }}>
            <Table stickyHeader sx={{ minWidth: 800 }}>
              <TableHead>
                <TableRow>
                  {COLUMNS.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{
                        minWidth: column.minWidth,
                        textTransform: 'uppercase',
                        background: "#D9D9D9",
                        color: primaryPresets[11].main,
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {rowData
                  ?.slice(
                    table.page * table.rowsPerPage,
                    table.page * table.rowsPerPage + table.rowsPerPage
                  )
                  .map((row) => (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                      {COLUMNS.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell
                            style={{ verticalAlign: 'top', textTransform: 'uppercase' }}
                            key={column.id}
                            align={column.align}
                          >
                            {column.render ? column.render(value, row, rowData?.length) : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </Scrollbar>
        </TableContainer>

        {/* <TablePaginationCustom
          count={rowData?.length}
          page={table.page}
          rowsPerPage={table.rowsPerPage}
          onPageChange={table.onChangePage}
          onRowsPerPageChange={table.onChangeRowsPerPage}
        /> */}
      </Paper>
      {/* <Box p={2} display="flex" justifyContent="flex-end">
        <HasAccess
          roles={['admin']}
          permissions={['accounting_client_backend_level1_admin_request']}
        >
          <LoadingButton
            sx={{ marginRight: 2 }}
            loading={loading}
            variant="contained"
            onClick={() => {
              onCompleteL2();
              addNotification();
            }}
          >
            SEND TO ADMIN FOR APPROVAL
          </LoadingButton>
        </HasAccess>
        <LoadingButton loading={loading} variant="contained" onClick={onCompleteL2}>
          MARK AS COMPLETE
        </LoadingButton>
      </Box> */}
      <CommentDialog open={showDialog} handleClose={setShowDialog} />
      <CommercialInvoiceDialog open={showInvoiceDialog} handleClose={setShowInvoiceDialog} />
    </Box>
  );
}
