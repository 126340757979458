/* eslint-disable jsx-a11y/label-has-associated-control */
import {
  Box,
  Button,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useState, useEffect } from 'react';
import { useRouter } from 'src/routes/hooks';
import CommentDialog from 'src/components/comment-dialog';
import Scrollbar from 'src/components/scrollbar';
import { useTable } from 'src/components/table';
import { calculateClientUnitPrice } from 'src/utils/quoteHelperFunc';
import VIewFile from 'src/components/upload/ViewFIle';
import { North, South } from '@mui/icons-material';
import { LineItemMoveDownApi, LineItemMoveUpApi } from 'src/services/common';
import { useSnackbar } from 'src/components/snackbar';
import CommentsModal from 'src/components/comments/index';
import CountComment from 'src/components/comments/CountComment';
import { primaryPresets } from 'src/theme/options/presets';
import { formatCurrencyWithSymbol } from 'src/utils/currencyFormat';

function createData(
  Id,
  slNo,
  description,
  PN,
  quantity,
  unit,
  make,
  unitPrice,
  totalPrice,
  invoice,
  index,
  RecentlyEditedBy
) {
  return {
    Id,
    slNo,
    description,
    PN,
    quantity,
    unit,
    make,
    unitPrice,
    totalPrice,
    invoice,
    index,
    RecentlyEditedBy
  };
}

export default function FrontendAccClientTable({ response, id, setRefresh, refresh }) {
  const router = useRouter();
  const table = useTable({ defaultRowsPerPage: 10000 });
  const [showDialog, setShowDialog] = useState(false);
  const [rowData, setRowData] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [isCommentOpen, setIsCommentOpen] = useState(false);
  const [commentId, setCommentId] = useState(null);

  const handleComments = (row) => {
    setCommentId(row?.Id);
    setIsCommentOpen(true);
  };

  useEffect(() => {
    if (response?.line_items?.length) {
      const rowArr = response?.line_items?.map((list, ind) => {
        return createData(
          list?._id,
          list?.serialNumber,
          list?.description ? list?.description : '--',
          list?.PN ? list?.PN : '--',
          list?.quantity ? list?.quantity : '--',
          list?.unit ? list?.unit : '--',
          list?.make ? list?.make : '--',
          formatCurrencyWithSymbol(calculateClientUnitPrice(list, response), response?.currency?.abbreviation),
          formatCurrencyWithSymbol(
            calculateClientUnitPrice(list, response) * list.quantity,
            response?.currency?.abbreviation
          ),
          response.invoice,
          ind,
          list?.RecentlyEditedBy ? list?.RecentlyEditedBy :[]
        );
      });

      setRowData(rowArr);
    }
  }, [response]);

  const COLUMNS = [
    {
      label: 'Action',
      id: 'action',
      width: 250,
      render: (value, row, length) => (
        <Box mr={2}>
          <Stack>
            {row?.index === 0 ? (
              ''
            ) : (
              <Button onClick={() => MoveUp(id, row?.Id)}>
                <North />
                <Typography variant="caption">MOVE UP</Typography>{' '}
              </Button>
            )}
            {row?.index + 1 === length ? null : (
              <Button onClick={() => MoveDown(id, row?.Id)}>
                <South />
                <Typography variant="caption">MOVE DOWN</Typography>{' '}
              </Button>
            )}
            {length === 1 ? <Typography variant="caption">No Action</Typography> : null}
          </Stack>
        </Box>
      ),
    },
    {
      id: 'slNo',
      label: 'SNO.',
      minWidth: 50,
      align:"center",
    },
    {
      id: 'description',
      label: 'Description',
      align:"center",
      render: (value, row) => <Box sx={{width:"100%", display:'flex', justifyContent:'center'}}><Typography style={{ wordBreak: 'break-word',width: 300 , pl:3}}>{value}</Typography></Box>,
    },
    {
      id: 'PN',
      label: 'P/N',
      minWidth: 200,
      align:"center",
    },
    {
      id: 'quantity',
      label: 'QUANTITY',
      minWidth: 100,
      align:"center",
    },
    {
      id: 'unit',
      label: 'Unit of Issue',
      minWidth: 120,
      align:"center",
    },
    {
      id: 'make',
      label: 'Make',
      minWidth: 200,
      align:"center",
      render: (value, row) => <Typography style={{ wordBreak: 'break-word' }}>{value}</Typography>,
    },
    {
      id: 'unitPrice',
      label: 'Unit Price',
      minWidth: 100,
      align:"center",
    },

    {
      id: 'totalPrice',
      label: 'Total Price',
      minWidth: 150,
      align:"center",
    },

    {
      id: 'comment',
      label: 'Comments',
      minWidth: 170,
      align:"center",
      render: (value, row) => (
        <Box
          onClick={() => {
            setShowDialog(true);
            handleComments(row);
          }}
          display="flex"
          flexDirection="column"
        >
          <Box sx={{ ml: 2.5 }}>
            {/* <Iconify width={20} icon="ic:outline-chat" /> */}
            <CountComment projectId={row?._id} isCommentOpen={isCommentOpen}/>
          </Box>
        </Box>
      ),
    },
    {
      id: 'deliveryProofChallan',
      label: 'Delivery Proof Challan',
      minWidth: 170,
      align:"center",
      render: (value) => (
        value?
        <VIewFile
          disabled={!value}
          sx={{ maxWidth: 150, wordWrap: 'break-word' }}
          label={'Delivery Proof Challan'}
          value={value}
        />:"N/A"
      ),
    },
    {
      id: 'invoice',
      label: 'Commercial Invoice',
      minWidth: 250,
      align:"center",
      render: (value) => (
        <Box sx={{width:250, display:'flex', alignItems:'center'}}>
        {value?
        <VIewFile
          disabled={!value}
          sx={{ maxWidth: 150, wordWrap: 'break-word' }}
          label={'invoice'}
          value={value}
        />:"N/A"}
        </Box>
      ),
    },
    {
      id: 'user',
      label: 'USER',
      minWidth: 150,
      render: (value, row) => (
        <Box>
          <Typography>
            Edited By:{' '}
            {row?.RecentlyEditedBy?.slice(-1)[0] ? row?.RecentlyEditedBy.slice(-1)[0] : '--'}
          </Typography>
        </Box>
      ),
    },
  ];

  const MoveDown = (id, lineItem_id) => {
    setLoading(true);
    LineItemMoveDownApi(id, lineItem_id)
      .then(() => {
        setRefresh(refresh + 1);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        enqueueSnackbar('Error! Please try again');
      });
  };

  const MoveUp = (id, lineItem_id) => {
    setLoading(true);
    LineItemMoveUpApi(id, lineItem_id)
      .then(() => {
        setRefresh(refresh + 1);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        enqueueSnackbar('Error! Please try again');
      });
  };

  return (
    <Box>
      <CommentsModal
        isCommentOpen={isCommentOpen}
        setIsCommentOpen={setIsCommentOpen}
        id={commentId}
      />

      <Paper elevation={1} sx={{p:2}}>
        <TableContainer sx={{ overflow: 'unset' }}>
          <Scrollbar sx={{ maxHeight: '80vh' }}>
            <Table stickyHeader sx={{ minWidth: 800 }}>
              <TableHead>
                <TableRow>
                  {COLUMNS.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ textTransform: 'uppercase', border: 1,background: "#D9D9D9",color:primaryPresets[11].main  }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {rowData
                  ?.slice(
                    table?.page * table?.rowsPerPage,
                    table?.page * table?.rowsPerPage + table?.rowsPerPage
                  )
                  .map((row) => (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                      {COLUMNS.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell
                            style={{ verticalAlign: 'top', textTransform: 'uppercase' }}
                            key={column.id}
                            align={column.align}
                          >
                            {column.render ? column.render(value, row, rowData?.length) : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </Scrollbar>
        </TableContainer>

        {/* <TablePaginationCustom
          count={rowData?.length}
          page={table.page}
          rowsPerPage={table.rowsPerPage}
          onPageChange={table.onChangePage}
          onRowsPerPageChange={table.onChangeRowsPerPage}
        /> */}
      </Paper>
      <CommentDialog open={showDialog} handleClose={setShowDialog} />
    </Box>
  );
}
