import React, { useEffect, useState } from 'react';
import { Box, Button, Chip, Container, Typography, alpha, TextField, Card } from '@mui/material';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import { useSettingsContext } from 'src/components/settings';
import { primaryPresets } from 'src/theme/options/presets';
import { useOutletContext } from 'react-router';
import EshopTable from '../eshopTable';
import { useParams } from 'src/routes/hooks';
import { ArrowDropDown } from '@mui/icons-material';
import { getQuote, updateQuotes } from 'src/services/quotes';
import { useSnackbar } from 'notistack';
import { useAuthContext } from 'src/auth/hooks';
import { HasAccess } from '@permify/react-role';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import HistoryIcon from '@mui/icons-material/History';
import { LoadingButton } from '@mui/lab';

export default function EshopView() {
  const { id } = useParams();
  const settings = useSettingsContext();
  const [data, loading] = useOutletContext();
  // const { user } = useAuthContext();
  // const { enqueueSnackbar } = useSnackbar();

  // const [refresh, setRefresh] = useState(0);
  // const [isRFQNameEdit, setIsRFQNameEdit] = useState(false);
  // const [isRFQNoEdit, setIsRFQNoEdit] = useState(false);
  // const [rfqNo, setRfqNo] = useState("");
  // const [rfqName, setRfqNumber] = useState("");
  // const [quoteData, setQuoteData] = useState([]);
  // useEffect(() => {
  //   getQuote(id).then((res) => setQuoteData(res));
  // }, [id, refresh]);

  
  // useEffect(() => {
  //   setRfqNo(data?.RFQ)
  // }, [data?.RFQ])
  // useEffect(() => {
  //   setRfqNumber(data?.RFQName)
  // }, [data?.RFQName])
  

  // async function updateRFQDetails(params) {
  //   let obj = {
  //     _id:id,
  //     userid:user._id
  //   }
  //   if(params == "RFQNAME"){
  //    obj["RFQName"] = rfqName;
  //   }else{
  //     obj["RFQ"] = rfqNo;
  //   }
  //   await updateQuotes({
  //     _id:id,
  //     RFQ:rfqNo,
  //     RFQName:rfqName,
  //     userid:user._id
  //   }).then((resp)=>{
  //     setRefresh(refresh + 1)
  //     enqueueSnackbar(resp.message);
  //     if(params === "RFQNAME"){
  //       setIsRFQNameEdit(false)
  //     }else{
  //       setIsRFQNoEdit(false)
  //     }
  //   }).catch((err)=>{
  //     console.log("err", err)
  //     enqueueSnackbar(err.data.message, { variant: 'error' });
  //   })
  // }

  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      <Box display="flex">
        <Box>
          <CustomBreadcrumbs
            heading="ESHOP"
            links={[
              { name: 'Home', href: '/home' },
              {
                name: 'Vendor',
                href: `/quote/${id}/vendor`,
              },
              {
                name: 'Vendor Selection',
                href: `/quote/${id}/vendor/vendor-selection`,
              },
              {
                name: 'ESHOP Order',
                href: `/quote/${id}/eshop/eshop-orders`,
              },
              {
                name: 'ESHOP',
                href: '/eshop',
              },
            ]}
            sx={{ mb: { xs: 3, md: 5 } }}
          />
          <Box display={'flex'} >
              <Button
                variant="contained"
                sx={{
                  borderRadius: 0,
                  color:"black",
                  ml: 1,
                  width: 240,
                  fontSize: 14,
                  backgroundColor: 'grey',
                  borderTop: '5px solid red'
                }}
              >
                ESHOP
              </Button>
              <Button
                href={`/quote/${id}/eshop/eshop-orders`}
                variant="contained"
                sx={{ borderRadius: 0,width: 240,color:"black", ml: 1, fontSize: 14, backgroundColor: 'gray',mt:1}}
              >
                ORDERS
              </Button>
              <Button
                href={`/quote/${id}/eshop/eshop-archive`}
                variant="contained"
                sx={{ borderRadius: 0,width: 240,color:"black", ml: 1, fontSize: 14, backgroundColor: 'gray', mt: 1 }}
              >
                ARCHIVE
              </Button>
          </Box>
        </Box>
        <Box borderLeft={2} marginLeft={2} paddingLeft={2} borderColor={"white"} color={'white'}>
                <Card sx={{p:2}}>
        <Typography textTransform="uppercase" variant="caption">{`Project ID: ${data?.projectId}`}</Typography><br/>
        <Typography textTransform="uppercase" variant="caption">{`RFQ: ${data?.RFQ}`}</Typography><br/>
        <Typography textTransform="uppercase" variant="caption">{`RFQ NAME: ${data?.RFQName}`}</Typography><br/>
        {/* <Typography textTransform="uppercase" variant="caption">RFQ:
        {
                  isRFQNoEdit ?
                  <><TextField
                    id="margin-none"
                    variant="outlined"
                    size="small"
                    multiline
                    type="text"
                    value={rfqNo}
                    onChange={(e) => setRfqNo(e.target.value)}
                    sx={{ 
                      pl:1, pb:1,width: 120,
                      '& .MuiOutlinedInput-root': {
                          margin: 0,
                        },
                    }}
                  />
                  <Box display='flex'  sx={{width:'100%', pl:2}}>
                  <LoadingButton
                      style={{ textTransform: 'uppercase', pl:2 }}
                      size="small"
                      disabled={rfqNo.length == 0}
                      type="submit"
                      variant="contained"
                      onClick = {() => {updateRFQDetails("RFQNO")}}
                    >
                      Update
                    </LoadingButton>
                  <HistoryIcon
                    title="BACK"
                     onClick={()=>setIsRFQNoEdit(false)} sx={{ marginLeft: 1, color:"red", cursor: 'pointer'}}/>
                  </Box>
                    
                  </> :
                  <>
                    {data?.RFQ}
                  {'  '}
                  <HasAccess roles={['admin']} permissions={['field_team_level4_view']}>

                  <ModeEditIcon
                    title="RFQ NAME EDIT"
                    onClick={()=>setIsRFQNoEdit(true)} sx={{ marginLeft: .5, fontSize: 'medium', color:"red", cursor: 'pointer'}}/>
                    </HasAccess>
                  </>
              }
        </Typography><br/>
        <Typography textTransform="uppercase" variant="caption">RFQ NAME:
        
        
        
        
        {
            isRFQNameEdit ?
                  <><TextField
                    id="margin-none"
                    variant="outlined"
                    size="small"
                    type="text"
                    multiline
                    value={rfqName}
                    onChange={(e) => setRfqNumber(e.target.value)}
                    sx={{ 
                      pl:1, pb:1,width: 120,
                      '& .MuiOutlinedInput-root': {
                          margin: 0,
                        },
                    }}
                  />
                  <Box display='flex'  sx={{width:'100%', pl:2}}>
                  <LoadingButton
                      style={{ textTransform: 'uppercase', pl:2 }}
                      size="small"
                      disabled={rfqNo.length == 0}
                      type="submit"
                      variant="contained"
                      onClick = {() => {updateRFQDetails("RFQNAME")}}
                    >
                      Update
                    </LoadingButton>
                  <HistoryIcon
                    title="BACK"
                     onClick={()=>setIsRFQNameEdit(false)} sx={{ marginLeft: 1, color:"red", cursor: 'pointer'}}/>
                  </Box>
                    
                  </> :
                  <>
                    {data?.RFQName}
                  {'  '}
                  <HasAccess roles={['admin']} permissions={['field_team_level4_view']}>
                  <ModeEditIcon
                    title="RFQ NAME EDIT"
                    onClick={()=>setIsRFQNameEdit(true)} sx={{ marginLeft: .5, marginTop:"2px", fontSize: 'medium', color:"red", cursor: 'pointer'}}/>
                    </HasAccess>
                  </>
              }
              </Typography><br/> */}
        <Typography textTransform="uppercase" variant="caption">'Recently edited by - {data?.RecentlyEditedBy?.slice(-1)[0] ? data?.RecentlyEditedBy?.slice(-1)[0]?.editedBy : '--'} </Typography>
        </Card>
        </Box>
        <Box borderLeft={2} marginLeft={2} paddingLeft={2} borderColor={"white"}>
          <Button
            href={`/quote/l1/${id}`}
            style={{background: 'white', color: 'black', borderRadius:"20px" }}
            variant="outlined"
          >
            <Typography textTransform="uppercase" variant="button" b>
              GO TO Quote LEVEL 1
            </Typography>
            <ArrowDropDown fontSize="small" sx={{ color: 'black' }} />
          </Button><br/>
          <Button
            href={`/quote/l2/${id}`}
            style={{background: 'white', color: 'black', borderRadius:"20px", marginTop:"10px" }}
            variant="outlined"
          >
            <Typography textTransform="uppercase" variant="button" b>
              GO TO Quote LEVEL 2
            </Typography>
            <ArrowDropDown fontSize="small" sx={{ color: 'black' }} />
          </Button>
        </Box>

        {/* <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          width: 1,
          paddingLeft: 4,
          paddingRight: 4,
          paddingTop: 3,
          paddingBottom: 3,
          borderRadius: 2,
          mt: 2,
          bgcolor: (theme) => alpha(theme.palette.grey[500], 0.04),
          border: (theme) => `solid 1px ${theme.palette.divider}`,
        }}
      >
        <Box>
          <Chip
            style={{ borderRadius: 1, marginRight: 10, textTransform:"uppercase" }}
            label={`Project ID: ${data?.projectId}`}
          />
          <Chip style={{ borderRadius: 1, textTransform:"uppercase" }} label={`RFQ: ${data?.RFQ}`} variant="outlined" />
        </Box>
        <Typography textTransform="uppercase" variant="caption">{'Recently edited by - {User Name}'}</Typography>
      </Box> */}
      </Box>
      <EshopTable
        loading={loading}
        data={data?.line_items?.map((i) => ({ ...i, currency: data.currency }))}
      />
    </Container>
  );
}
