/* eslint-disable jsx-a11y/label-has-associated-control */
import {
  Box,
  Button,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  InputAdornment,
} from '@mui/material';
import * as Yup from 'yup';
import { useSnackbar } from 'src/components/snackbar';
import { yupResolver } from '@hookform/resolvers/yup';
import { useState, useMemo, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import CommentDialog from 'src/components/comment-dialog';
import Iconify from 'src/components/iconify/iconify';
import Scrollbar from 'src/components/scrollbar';
import { RHFTextField } from 'src/components/hook-form';
import { primaryPresets } from 'src/theme/options/presets';
import { fCurrency } from 'src/utils/format-number';
import { calculateClientUnitPrice } from 'src/utils/quoteHelperFunc';
import { adminApproval, generatePO, updateAccounting } from 'src/services/vendorAcc';
import LoadingAsync from 'src/components/common/LoadingAsync';
import VIewFile from 'src/components/upload/ViewFIle';
import { useParams } from 'src/routes/hooks';
import { getSignedURltoviewFile } from 'src/services/file';
import CommentsModal from 'src/components/comments/index';
import { updateInitiateDelivery } from 'src/services/delivery';
import { Cancel } from '@mui/icons-material';
import CountComment from 'src/components/comments/CountComment';
import { useNavigate } from 'react-router-dom';
import { HasAccess, usePermify } from '@permify/react-role';
import UploadManualFiles from 'src/components/upload/UploadMultipleDocs';
import UploadFile from 'src/components/upload/uploadFileButton';

export default function BackendAccVendorLevel2Table({
  data,
  projectData,
  setVendorData,
  handleTriggered, // for ReFreshing Page
}) {
  const { enqueueSnackbar } = useSnackbar({ autoHideDuration: 3000 });
  const { id } = useParams();
  const navigate = useNavigate();
  const { isAuthorized, isLoading } = usePermify();
  const [backendAuth, setBackendAuth] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [currency, setCurrency] = useState('');
  const [formData, setFormData] = useState({});
  const openlink = async (i) => {
    let file = await getSignedURltoviewFile(i);
    window.open(file);
  };

  const [isCommentOpen, setIsCommentOpen] = useState(false);
  const [commentId, setCommentId] = useState(null);

  useEffect(() => {
    const callAuth = async () => {
      let tempAuth = await isAuthorized([], 'backend');
      await setBackendAuth(tempAuth);
    };
    callAuth();
  }, []);

  const handleComments = (row) => {
    setCommentId(row?._id);
    setIsCommentOpen(true);
  };

  const handleInitiatePickup = (data) => {
    updateInitiateDelivery(data._id)
      .then((response) => {
        enqueueSnackbar('Delivery Initiated', { variant: 'success' });
      })
      .catch((error) => {
        enqueueSnackbar('Unable to Accept!', { variant: 'error' });
      });
  };

  const COLUMNS = [
    {
      id: 'index',
      label: 'SNO.',
      minWidth: 50,
      align: 'center',
    },
    {
      id: 'desc',
      label: 'Description',
      minWidth: 150,
      align: 'center',
      render: (val, row) => (
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <Typography style={{ wordBreak: 'break-word', width: 300, pl: 3 }}>
            {row?.line_item?.description}
          </Typography>
        </Box>
      ),
    },
    {
      id: 'pn',
      label: 'P/N',
      align: 'center',
      minWidth: 200,
      render: (val, row) => row?.line_item?.PN,
    },
    {
      id: 'unitPrice',
      label: '(Client) Unit Price',
      minWidth: 120,
      align: 'center',
      render: (val, row) => (
        <>
          {' '}
          {fCurrency(
            calculateClientUnitPrice(row?.line_item, projectData),
            projectData?.currency?.symbol
          )}
        </>
      ),
    },
    {
      id: 'totalPrice',
      label: '(Client) Total Price',
      minWidth: 130,
      align: 'center',
      render: (value, row) => (
        <>
          {' '}
          {fCurrency(
            calculateClientUnitPrice(row?.line_item, projectData) * row?.line_item?.quantity,
            projectData?.currency?.symbol
          )}
        </>
      ),
    },
    {
      id: 'qty',
      label: 'QUANTITY',
      minWidth: 100,
      align: 'center',
      render: (value, row) => row?.quote?.quantity,
    },
    {
      id: 'unitIssue',
      label: 'Unit of Issue',
      minWidth: 120,
      align: 'center',
      render: (value, row) => row?.line_item?.unit,
    },
    {
      id: 'make',
      label: 'Make',
      align: 'center',
      minWidth: 200,
      render: (value, row) => (
        <Typography style={{ wordBreak: 'break-word' }}>{row?.line_item?.make}</Typography>
      ),
    },
    {
      id: 'deliveryLeadTime',
      label: 'Delivery Timeline',
      minWidth: 100,
      align: 'center',
      render: (value, row) =>
        `${row?.line_item?.deliveryLeadTime} ${row?.line_item?.deliveryLeadTimeUnit}`,
    },
    {
      id: 'vendorRef',
      label: 'Vendor Type',
      minWidth: 170,
      align: 'center',
      render: (val, row) => (
        <Chip
          onClick={() => {
            if (backendAuth === false) {
              if (row?.type == 'vendor') {
                navigate(`/${id}/${row?.partId}/vendor-quote-orders/${row?.vendorId?._id}`);
              } else {
                navigate(`/quote/${id}/eshop/eshop-purchase-plan/${row?.partId}`);
              }
            }
          }}
          style={{ textTransform: 'uppercase' }}
          label={row?.type}
          color={row?.type == 'vendor' ? 'primary' : 'secondary'}
        ></Chip>
      ),
    },

    {
      id: 'vendorName',
      label: 'Vendor Name',
      minWidth: 150,
      align: 'center',
      render: (val, row) => (
        <Box display="flex" flexDirection="column">
          <Typography
            textTransform="uppercase"
            variant="body2"
            style={{ textDecoration: 'underline', marginBottom: 8 }}
          >
            {row?.vendorId?.name || row?.eshopName}
          </Typography>
        </Box>
      ),
    },
    {
      id: 'vendorQuoteFile',
      label: 'Vendor Quote File',
      minWidth: 150,
      align: 'center',
      render: (val, rows) => (
        <Box display="flex" flexDirection="column">
          <VIewFile
            value={rows?.quote?.vendorQuoteFile}
            disabled={!rows?.quote?.vendorQuoteFile}
            label={'file'}
          />
          {/* <Button component="label" variant="contained">
            <Typography textTransform="uppercase" variant="button">View File</Typography>
          </Button> */}
        </Box>
      ),
    },
    {
      id: 'vendorQty',
      label: 'Vendor Qty',
      minWidth: 130,
      align: 'center',
      render: (value, row) => row?.quantityApproved,
    },
    {
      id: 'purchaseUnitPrice',
      label: 'VENDOR Unit Price', // Actual Purchase
      minWidth: 170,
      align: 'center',
      render: (value, row) => fCurrency(row?.quote?.price, projectData?.currency?.symbol),
    },
    {
      id: 'purchaseTotalPrice',
      label: 'Vendor Total Price', // Actual Purchase
      minWidth: 170,
      align: 'center',
      render: (value, row) =>
        fCurrency(row?.quote?.price * row?.quantityApproved, projectData?.currency?.symbol),
    },
    {
      id: 'actualShippingCost',
      label: 'Actual Shipping Cost',
      minWidth: 270,
      align: 'center',
      render: (val, row) => (
        <Box>
          <Box sx={{ mb: 0.8 }} display="flex" flexDirection="row">
            <RHFTextField
              sx={{ textTransform: 'uppercase' }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {row?.quote?.currency?.symbol || '$'}
                  </InputAdornment>
                ),
              }}
              fullWidth
              size="small"
              type="number"
              label="Est. Shipping Cost"
              name={'estimatedShipping'}
            />
          </Box>
          <Box sx={{ mb: 0.8 }} display="flex" flexDirection="row">
            <RHFTextField
              sx={{ textTransform: 'uppercase' }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {row?.quote?.currency?.symbol || '$'}
                  </InputAdornment>
                ),
              }}
              fullWidth
              size="small"
              type="number"
              label="Actual Shipping Cost"
              name={'actualShipping'}
            />
          </Box>
          <Box sx={{ mb: 0.8 }} display="flex" flexDirection="row">
            <RHFTextField
              sx={{ textTransform: 'uppercase' }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {row?.quote?.currency?.symbol || '$'}
                  </InputAdornment>
                ),
              }}
              fullWidth
              size="small"
              type="number"
              label="Any Tax Amt."
              name={'tax'}
            />
          </Box>
        </Box>
      ),
    },
    {
      id: 'miscAmount',
      label: 'Misc. Amount',
      minWidth: 250,
      render: (val, row) => (
        <Box>
          <Box sx={{ mb: 0.8 }} display="flex" flexDirection="row">
            <RHFTextField
              sx={{ textTransform: 'uppercase' }}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {row?.quote?.currency?.symbol || '$'}
                  </InputAdornment>
                ),
              }}
              size="small"
              type="number"
              label="Custom Duty Cost"
              name="custom"
            />
          </Box>
          <Box sx={{ mb: 0.8 }} display="flex" flexDirection="row">
            <RHFTextField
              sx={{ textTransform: 'uppercase' }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {row?.quote?.currency?.symbol || '$'}
                  </InputAdornment>
                ),
              }}
              fullWidth
              size="small"
              type="number"
              label="Misc. Cost"
              name="misc"
            />
          </Box>
        </Box>
      ),
    },
    {
      id: 'targetUnitPrice',
      label: 'Target Unit Price',
      minWidth: 120,
      align: 'center',
      render: (value, row) => (
        <RHFTextField
          sx={{ textTransform: 'uppercase' }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {row?.quote?.currency?.symbol || '$'}
              </InputAdornment>
            ),
          }}
          fullWidth
          size="small"
          type="number"
          label="Target unit price"
          name="targetPrice"
        />
      ),
    },
    {
      id: 'targetTotalPrice',
      label: 'Target Total Purchase Price',
      minWidth: 170,
      align: 'center',
      render: (values, row) =>
        fCurrency(values.targetPrice * row?.quote.quantity, row?.quote?.currency?.symbol),
    },
    {
      id: 'vendorPoFile',
      label: 'Vendor PO File',
      minWidth: 120,
      align: 'center',
      render: (val, row, error, setvalue, setRegeneratePoFile, regeneratePOFIle) => (
        <Box display="flex" flexDirection="column">
          {backendAuth === false ? (
            <>
              {row.isPOreceived && !regeneratePOFIle ? (
                // row?.type === 'eshop' ? (
                //   <Button
                //     variant="contained"
                //     // disabled={row?.type === 'eshop'}
                //     href={`/quote/generatePOPdf/${row?._id}/${id}`}
                //     startIcon={<Iconify width={20} icon="heroicons:folder-plus-20-solid" />}
                //   >
                //     <Typography textTransform="uppercase" variant="button">
                //       Generate12
                //     </Typography>
                //   </Button>
                // ) : (
                <Box display="flex">
                  <VIewFile label="PO" value={row.poFile} mt={2} />
                  <Cancel style={{ cursor: 'pointer' }} onClick={() => setRegeneratePoFile(true)} />
                </Box>
              ) : (
                // )
                //   <>
                <Button
                  variant="contained"
                  disabled={!row.adminApproval}
                  href={`/quote/generatePOPdf/${row?._id}/${id}?ui=${row?.quote?.price * row?.quantityApproved}`}
                  startIcon={<Iconify width={20} icon="heroicons:folder-plus-20-solid" />}
                >
                  <Typography textTransform="uppercase" variant="button">
                    Generate
                  </Typography>
                </Button>
              )}
            </>
          ) : (
            '---'
          )}
        </Box>
      ),
    },
    {
      id: 'vmanualpo',
      label: 'Manual Vendor PO File',
      minWidth: 120,
      align: 'center',
      render: (val, row, error, setvalue, setRegeneratePoFile, regeneratePOFIle) => {
        return (
          <Box display="flex" flexDirection="column">
            {backendAuth === false ? (
              <>
                <Box display="flex" flexDirection="column" alignItems="flex-center">
                  <UploadFile
                    label="Manual Vendor PO FILE"
                    path="vendor/manual-po"
                    value={val.vmanualpo}
                    fileNameColor="black"
                    setValue={(val) => {
                      setvalue('vmanualpo', val ? val : '');
                    }}
                  />
                </Box>
              </>
            ) : (
              '---'
            )}
          </Box>
        );
      },
    },
    {
      id: 'deliveryTimeLine',
      label: 'Vendor Delivery Lead Time',
      minWidth: 170,
      align: 'center',
      render: (val, row) => `${row?.quote?.deliveryTimeline} ${row?.quote?.deliveryLeadTimeUnit}`,
    },
    {
      id: 'approvalAdmin',
      label: 'Approval of Admin (Before Payment)',
      minWidth: 180,
      align: 'center',
      render: (val) => {
        return (
          <Chip
            style={{ textTransform: 'uppercase' }}
            color={
              val?.adminApproval == 'Approved'
                ? 'success'
                : val?.adminApproval == 'Rejected'
                  ? 'error'
                  : 'warning'
            }
            label={val.adminApproval ? 'Approved' : 'Pending'}
            variant="contained"
            sx={{ bgcolor: primaryPresets[6].main, color: 'black' }}
          />
        );
      },
    },
    {
      id: 'paymentAmtVendor',
      label: 'Advance Payment Amt. for Vendor',
      minWidth: 210,
      align: 'center',
      render: (value, row) => (
        <RHFTextField
          sx={{ textTransform: 'uppercase' }}
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {row?.quote?.currency?.symbol || '$'}
              </InputAdornment>
            ),
          }}
          size="small"
          type="number"
          label="Advance payment"
          name="amountPayed"
        />
      ),
    },
    {
      id: 'balanceAmtVendor',
      label: 'Balance Payment Amt. for Vendor',
      minWidth: 210,
      align: 'center',
      render: (values, row) =>
        fCurrency(
          values.targetPrice * row?.quote.quantity - values.amountPayed,
          row?.quote?.currency?.symbol
        ),
    },

    {
      id: 'comment',
      label: 'Comments',
      minWidth: 170,
      align: 'center',
      render: (value, row) => (
        <Box
          onClick={() => {
            setShowDialog(true);
            handleComments(row);
          }}
          display="flex"
          flexDirection="column"
        >
          <Box sx={{ ml: 2.5 }}>
            <CountComment projectId={row?._id} isCommentOpen={isCommentOpen} />
          </Box>
        </Box>
      ),
    },
    {
      id: 'initiateDelivery',
      label: 'Delivery Status from Vendor for Shipping',
      minWidth: 210,
      align: 'center',
      render: (value, row) =>
        row?.initiateDelivery ? (
          <Chip
            // onClick={() => handleInitiatePickup(row)}
            style={{ textTransform: 'uppercase' }}
            label="Pickup for Delivery"
            variant="contained"
            sx={{ bgcolor: primaryPresets[6].main, color: 'black' }}
          />
        ) : (
          <Chip
            onClick={() => handleInitiatePickup(row)}
            style={{ textTransform: 'uppercase' }}
            label="Initiate Delivery"
            variant="contained"
            sx={{ bgcolor: primaryPresets[8].light, color: 'black' }}
          />
        ),
    },
    {
      id: 'vtaxInvoice',
      label: 'Tax Invoice of Vendor',
      minWidth: 110,
      align: 'center',
      render: (values, row, errors, setValue) => (
        <Box>
          <UploadManualFiles
            label="Tax Invoice"
            path="acc/vendorTaxInvoice"
            value={values?.vtaxInvoice}
            sx={{
              width: '80%', // Adjust width to ensure they fit within the available space
            }}
            setValue={(val) => setValue('vtaxInvoice', val)}
            // deleteSelectedFile={(filesName) => openDeleteConfirmationModal("commercial", filesName)}
          />
        </Box>
      ),
    },
    {
          id: 'user',
          label: 'USER',
          minWidth: 150,
          render: (values, row) => (
            <Box>
              <Typography>
                Edited By:{' '}
                {row?.RecentlyEditedBy?.slice(-1)[0] ? row?.RecentlyEditedBy?.slice(-1)[0]?.editedBy : '--'}
              </Typography>
            </Box>
          ),
        },
  ];

  const onChangeCurrency = (e) => {
    setCurrency(e.target.value);
  };

  const onChangeText = (field, val) => {
    setFormData({ ...formData, [field]: val.target.value });
  };

  return (
    <Box>
      <CommentsModal
        isCommentOpen={isCommentOpen}
        setIsCommentOpen={setIsCommentOpen}
        id={commentId}
      />
      <Paper elevation={1} sx={{ p: 2, mb: 1 }}>
        <TableContainer sx={{ overflow: 'unset' }}>
          <Scrollbar sx={{ maxHeight: '74vh' }}>
            <Table stickyHeader sx={{ minWidth: 800 }}>
              <TableHead>
                <TableRow>
                  {COLUMNS.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{
                        minWidth: column.minWidth,
                        textTransform: 'uppercase',
                        border: 1,
                        background: '#D9D9D9',
                        color: primaryPresets[11].main,
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                  <TableCell
                    key={'action'}
                    align="center"
                    style={{
                      position: 'sticky',
                      right: 0,
                      borderLeft: '1px solid white',
                      zIndex: 100,
                      minWidth: 150,
                      textTransform: 'uppercase',
                      border: 1,
                      top: 0,
                      background: '#D9D9D9',
                      color: primaryPresets[11].main,
                    }}
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {data.map((row) => (
                  <CustomTableRow row={row} COLUMNS={COLUMNS} handleTriggered={handleTriggered} />
                ))}
              </TableBody>
            </Table>
          </Scrollbar>
        </TableContainer>
      </Paper>
      <CommentDialog open={showDialog} handleClose={setShowDialog} />
    </Box>
  );
}

const CustomTableRow = ({ COLUMNS, row, currency, handleTriggered }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [regeneratePOFIle, setRegeneratePoFile] = useState(false);

  const QuoteSchema = Yup.object().shape({
    estimatedShipping: Yup.number().required(),
    actualShipping: Yup.number().required(),
    tax: Yup.number().required('Tax ammount required'),
    custom: Yup.number().required(),
    misc: Yup.number().required(),
    advancePayment: Yup.number(),
    amountPayed: Yup.number(),
    targetPrice: Yup.number().required(),
  });
  const defaultValues = useMemo(
    () => ({
      estimatedShipping: row?.estimatedShipping || null,
      actualShipping: row?.actualShipping || null,
      tax: row?.tax || null,
      custom: row?.custom || null,
      misc: row?.misc || null,
      amountPayed: row?.amountPayed || null,
      adminApproval: row?.adminApproval || false,
      targetPrice: row?.targetPrice || null,
      vtaxInvoice: row?.vtaxInvoice || [],
      vmanualpo: row?.vmanualpo || '',
    }),
    [row, currency]
  );

  const methods = useForm({
    resolver: yupResolver(QuoteSchema),
    defaultValues,
  });

  const {
    reset,
    watch,
    setValue,
    handleSubmit,

    formState: { isSubmitting, errors },
  } = methods;

  const values = watch();

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const onSubmit = handleSubmit(async (data) => {
    try {
      await updateAccounting(row?._id, data);
      enqueueSnackbar('Accounting Data Updated');
      setValue('isQuoteSubmitted', true);
      handleTriggered();
    } catch (error) {
      enqueueSnackbar('Error in creating project!', { variant: 'error' });
    }
  });

  return (
    <FormProvider {...methods}>
      <TableRow tabIndex={-1} key={row.code}>
        {COLUMNS.map((column) => {
          const value = row[column.id];
          return (
            <TableCell style={{ textTransform: 'uppercase' }} key={column.id} align={column.align}>
              {column.render
                ? column.render(
                    values,
                    row,
                    errors,
                    setValue,
                    setRegeneratePoFile,
                    regeneratePOFIle
                  )
                : value}
            </TableCell>
          );
        })}
        <TableCell
          style={{
            position: 'sticky',
            right: 0,
            borderLeft: '1px solid #D9D9D9',
            background: '#fff',
            zIndex: 100,
          }}
        >
          <Button style={{ textTransform: 'uppercase' }} variant="outlined" onClick={onSubmit}>
            Save
          </Button>
          <LoadingAsync
            label={'Approve Payment'}
            loadFunc={adminApproval}
            handleResponse={(res) => {
              setValue('adminApproval', true);
            }}
            handleErr={(err) => console.log(err)}
            params={[row._id]}
            buttonProps={{
              disabled: row?.adminApproval || values.adminApproval,
              variant: 'contained',
              disabled: row?.adminApproval,
              sx: { mt: 2 },
            }}
          ></LoadingAsync>
        </TableCell>
      </TableRow>
    </FormProvider>
  );
};
