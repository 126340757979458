import React, { Component, useState } from 'react';
import { Button, DialogContent, ListItem, ListItemText, Divider, Typography, Box, Chip, Grid } from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { getSignedURltoviewFile } from 'src/services/file';
import { fCurrency } from 'src/utils/format-number';
import { HasAccess } from '@permify/react-role';
import { display } from '@mui/system';
import PdfPreviewPopup from 'src/components/common/pdfPreview';
import Iconify from 'src/components/iconify/iconify';


export default function CommercialInvoiceAndBinder({ row, podFilteredForRow, sx }) {
  const [open, setOpen] = useState(false);
  const openlink = async (i) => {
    let file = await getSignedURltoviewFile(i);
    window.open(file);
  };
  
  return (
    <>
      <Dialog keepMounted onClose={() => setOpen(false)} open={open} fullWidth={true} maxWidth={'xl'}>
      <DialogTitle>Delivery Proof Files</DialogTitle>
      <DialogTitle>Project Id: {row?.projectId  }</DialogTitle>
      {podFilteredForRow && podFilteredForRow[0] ? 
        <DialogContent sx={{ minHeight: 300 }}>
  {/* Header Row */}
  <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center', mb: 4 }}>
    <Typography sx={{ flex: 1, textTransform: 'uppercase', fontWeight: 600, color: '#000' }}>
      Tracking ID
    </Typography>
    <Typography sx={{ flex: 1, textTransform: 'uppercase', fontWeight: 600, color: '#000' }}>
      Delivery Challan
    </Typography>
    <Typography sx={{ flex: 1, textTransform: 'uppercase', fontWeight: 600, color: '#000' }}>
      Signed Challan
    </Typography>
  </Box>

  {/* Data Rows */}
  {podFilteredForRow &&
    podFilteredForRow[0] &&
    podFilteredForRow[0]?.trackingIds_Signed_deliveryChallans?.map((i, index) => (
      <React.Fragment key={index}>
        <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center', my: 2 }}>
          {/* Tracking ID Column */}
          <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {i?.trackingId ? (
              <Typography sx={{ textTransform: 'uppercase' }}>
                {index + 1}. {i.trackingId}
              </Typography>
            ) : (
              <Typography>No File</Typography>
            )}
          </Box>

          {/* Delivery Challan Column */}
          <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {i?.deliveryChallanGeneratedCopy ? (
              <PdfPreviewPopup pdfUrl={i?.deliveryChallanGeneratedCopy} label="View Delivery Challan" />
            ) : (
              <Typography>No File</Typography>
            )}
          </Box>

          {/* Signed Challan Column */}
          <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {i?.binderFilePath ? (
              <PdfPreviewPopup pdfUrl={i?.deliveryChallanSignedCopy} label="View Signed Challan" />
            ) : (
              <Typography>No File</Typography>
            )}
          </Box>
        </Box>

        <Divider />
      </React.Fragment>
    ))}
</DialogContent>
         :<DialogContent sx={{ minHeight: 300, display:'flex', justifyContent:'center', alignItems:'center' }}> <Typography> No File Generated</Typography></DialogContent>}
      </Dialog>
      <Button
      sx={{
        color:"black",
              backgroundColor: '#D9D9D9',
              px: 5,
              borderRadius: 5,
              mt: 2,
              mr:2,
              ...sx
            }}
        style={{ textTransform: 'uppercase' }}
        variant={'contained'}
        onClick={() => setOpen(true)}
      >
         Proof of delivery
      </Button>
    </>
  );
}
